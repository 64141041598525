import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import { Button } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import MDXComponents from 'src/components/mdx-components';
import { InlineList } from 'src/components/molecules/InlineList';
import { Section } from 'src/components/molecules/Section';
import { Tab, Tabs } from 'src/components/organisms/Tabs';

export default function Drawer({ heading, content, contentNode, callouts, ctas, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  customData = JSON.parse(customData);

  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        <h2>{heading}</h2>
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        {callouts.length > 0 ? (
          <Tabs>
            {callouts.map(({ id, heading, contentNode, customData }, i) => {
              customData = JSON.parse(customData);

              return (
                <Tab
                  key={i}
                  title={heading}
                  id={id}
                  icon={!!customData?.icon && <Icon icon={customData.icon} />}
                >
                  <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>
                </Tab>
              );
            })}
          </Tabs>
        ) : null}
        {ctas.length > 0 && (
          <InlineList centered style={{ width: '100%', paddingTop: 80 }}>
            {ctas.map((cta, i) => {
              const ctaFlags = i > 0 ? { secondary: true } : {};
              return (
                <li key={i}>
                  <Button to={cta.url} {...ctaFlags}>
                    {cta.text}
                  </Button>
                </li>
              );
            })}
          </InlineList>
        )}
      </Section>
    </MDXProvider>
  );
}
