import get from 'lodash/get';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1.5rem;
  margin-bottom: 1.75rem;
`;
const TabTitle = styled.a`
  display: flex;
  padding: 0.5rem;
  border-left: 2px solid currentColor;
  transition: 0.2s ease all;
  color: inherit;
  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.primaryColor};
    `}

  &:hover,
  &:active {
    background-color: #fafafa;
    text-decoration: none;
  }
`;
const TabTitleIcon = styled.span`
  display: block;
  width: 2rem;
  text-align: center;
`;
export const Tab = ({ children }) => {
  return <div>{children}</div>;
};
Tab.defaultProps = {
  __TYPE: 'Tab',
};
export const Tabs = ({ defaultActiveTab, children, ...restProps }) => {
  const tabChildren = React.Children.toArray(children).filter((child) => {
    const childAsTab = child; // MDX wraps the component, check for that and act accordingly

    return !!childAsTab.props.originalType
      ? childAsTab.props.originalType === Tab
      : childAsTab.props.__TYPE === 'Tab';
  });
  const [activeTab, setActiveTab] = useState(defaultActiveTab || get(tabChildren, '0.props.id'));

  function handleTabClick(e, id) {
    e.preventDefault();
    setActiveTab(id);
  }

  return (
    <Container {...restProps}>
      <div>
        {tabChildren.map((child, index) => {
          const childAsTab = child;
          const title = get(childAsTab, 'props.title');
          const id = get(childAsTab, 'props.id');
          const icon = get(childAsTab, 'props.icon');
          const active = id === activeTab;
          return (
            <TabTitle key={index} active={active} onClick={(e) => handleTabClick(e, id)} href={`#${id}`}>
              <TabTitleIcon>{icon}</TabTitleIcon>
              {title}
            </TabTitle>
          );
        })}
      </div>
      <div>{tabChildren.find((child) => child.props.id === activeTab)}</div>
    </Container>
  );
};
